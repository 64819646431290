<template>
  <div>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ $t("编辑") }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="batch_number" :label="$t('批次编号')">
            <a-input v-model="form.batch_number" />
          </a-form-model-item>
          <a-form-model-item prop="remain_quantity" :label="$t('库存数量')">
            <a-input-number v-model="form.remain_quantity" style="width: 100%" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { subBatchUpdate } from "@/api/material";
import rules from "./rules.js";

export default {
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          subBatchUpdate({
            id: this.form.id,
            update_batch_number: this.form.batch_number,
            remain_quantity: this.form.remain_quantity,
          })
            .then((data) => {
              this.$message.success(this.$t("修改成功"));
              this.$emit("update", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
